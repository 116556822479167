.wrapper {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  padding: 8px 0px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.7);
  flex-wrap: wrap;
  max-width: 300px;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 8px;
  max-width: 130px;
}

.image {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text {
  color: #17172e;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: center;
  min-height: 44px;
}

@media (max-width: 1560px) {
  .wrapper {
    bottom: 120px;
  }
}

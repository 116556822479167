.wrapper {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 8%);
  height: 100vh;
  background: #fff;
  padding: 0px 4%;
}
.title {
  color: #17172e;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 26.4px */
  margin-top: 20px;
  margin-bottom: 16px;
}
.subtitle {
  color: #17172e;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 23.4px */
}
@media (max-width: 1024px) {
  .wrapper {
    display: flex;
  }
}

.wrapper {
  position: fixed;
  width: 100%;
  max-width: 650px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-auto-columns: auto;
  text-align: center;
}

.title {
  color: #37cc8f;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 23.4px */
  margin-top: 12px;
  margin-bottom: 6px;
}

.subtitle {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.hint {
  width: fit-content;
  margin: 0 auto;
  margin-top: 120px;
  display: flex;

  &Info {
    padding: 12px 16px;
    border-radius: 10px 0px 0px 10px;
    background: #fff;
    text-align: left;
  }
  &Title {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 18.2px */
  }
  &Subtitle {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }
  &Stop {
    transition: all 0.2s;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 0px 10px 10px 0px;
    padding: 16px 22px;
    background: #e41f2a;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */

    &:hover {
      background: #d21c25;
    }
  }
}

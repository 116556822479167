.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 12px;
    padding-top: 12px;
}

.wrap {
    display: flex;
    align-items: center;
}
.wrapper {
  padding: 8px 12px;
  border-top: 1px solid #dfdfdf;
  background: #fff;
  box-shadow: 0px -4px 20px 0px rgba(23, 23, 46, 0.1);
  display: flex;
  align-items: center;
  position: absolute;
  width: -webkit-fill-available;
  justify-content: space-between;
  z-index: 9;
  bottom: 0;
  height: 43px;
}
.widgets {
  display: flex;
  align-items: center;
}
.fullWindow {
  cursor: pointer;
  transition: all .2s;
  display: flex;
  border-radius: 10px;
  background: #f6f6f6;
  padding: 10px;
  margin-right: 4px;
  &:hover {
    background: #e4e3e3;
  }
}

.reset {
  transition: all .2s;
  cursor: pointer;
  border-radius: 10px;
  background: #f6f6f6;
  padding: 10px;
  display: flex;
  align-items: center;
  span {
    display: flex;
    color: #17172e;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 4px;
  }
  &:hover {
    background: #e4e3e3;
  }
}

.render {
  transition: all 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background: #37cc8f;
  padding: 0px 10px;
  height: 44px;

  span {
    display: flex;
    margin-left: 10px;
    color: #17172e;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &:hover {
    background: #32c487;
  }
}

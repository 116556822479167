.wrapper {
  width: 180px;
  position: relative;
  margin-right: 8px;
  input {
    height: 18px;
    width: -webkit-fill-available;
    border-radius: 10px;
    border: 1px solid #ced4da;
    background: #fff;
    padding: 8px;
    padding-left: 38px;
    outline: none;
    color: #17172e;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  label {
    display: flex;
    cursor: pointer;
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
}

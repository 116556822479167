.wrapper {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
}

.dimensions {
  transition: all 0.2s;
  padding: 12px 16px;
  height: 24px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background: #fff;
  backdrop-filter: blur(7px);
  span {
    color: #040503;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 4px;
  }
  
}

.save {
  transition: all 0.2s;
  cursor: pointer;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 10px 30px 0px rgba(23, 23, 46, 0.1);
  display: flex;
  align-items: center;
  padding: 10px 12px;
  height: 28px;
  border: 1px solid transparent;

  span {
    width: max-content;

    margin-left: 4px;
    color: #17172e;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
  }

  &:hover {
    border: 1px solid rgba(55, 204, 142, 1);
  }
}

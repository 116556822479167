.title {
  position: relative;
  z-index: 9;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  height: 23px;
}
.camera {
  position: relative;
  transition: all 0.2s;
  cursor: pointer;
  display: flex;
  
  margin: 0px 12px;
  align-items: center;
  transition: all 0.2s;
  border-radius: 10px;
  border: 1px solid transparent;
  border-bottom: 1px solid #dfdfdf;
  background: #fff;
  span {
    width: max-content;

    color: #17172e;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
    margin: 0px 4px;
  }
  .dropdown {
    transition: all .2s;
  }

  &.active {
    border-radius: 0px 0px 10px 10px;

    .content {
      opacity: 1;
      bottom: 47px;
      visibility: visible;
      z-index: 9;
      overflow: visible;
    }
    .dropdown {
      transform: rotate(180deg);
    }
    &:hover {
      border: 1px solid transparent;

    }
  }

  border: 1px solid transparent;

  &:hover {
    border: 1px solid rgba(55, 204, 142, 1);

  }
}

.content {
  transition: all 0.2s;
  opacity: 0;
  z-index: -1;
  overflow: hidden;
  visibility: hidden;
  position: absolute;
  background: #fff;
  bottom: 0;
  width: -webkit-fill-available;
  left: 0;
  border-radius: 10px 10px 0px 0px;

 
}

.item {
  transition: all .2s;
  background: #fff;
  padding: 12px;
  text-align: center;
  color: #17172e;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
  &:first-child {
    border-radius: 10px 10px 0px 0px;
  }

  &:hover {
    background: #37CC8F;
  }
  &.active {
    background: #37CC8F;
  }
}

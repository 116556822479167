.wrapper {
  position: relative;
  background: #fff;
  width: 360px;
  padding-bottom: 60px;
}

.title {
  color: #17172e;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.titleWrapper {
  padding: 12px;
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  width: -webkit-fill-available;
  justify-content: space-between;
  align-items: center;
}

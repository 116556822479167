.wrapper {
  padding: 0px 12px;
  overflow-y: auto;
  max-height: calc(100vh - 120px);
}
.title {
  color: #7d7d8a;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 15.6px */
  margin-top: 16px;
}

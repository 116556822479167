.wrapper {
  position: fixed;
  width: 100%;
  max-width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-auto-columns: auto;
  text-align: center;
}

.title {
  color: #37cc8f;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 23.4px */
  margin-top: 30px;
  margin-bottom: 6px;
}

.subtitle {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.wrapper {
    display: flex;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
@media (max-width: 1024px) {
    .wrapper {
        display: none;
    }
}
.wrapper {
  position: relative;
}
.title {
  transition: all 0.2s;
  cursor: pointer;
  color: #17172e;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 15.6px */
  display: flex;
  align-items: center;

  svg {
    path {
      transition: all 0.2s;
    }
  }

  &.active {
    svg {
      path {
        fill: #37cc8f;
      }
    }
  }
}

.content {
  position: absolute;
  top: 40px;
  right: 0;
  padding: 4px 12px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
}

.item {
  transition: all 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 4px 0;

  .icon {
    display: flex;
    align-items: center;
    transition: all 0.2s;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    margin-right: 4px;

    path {
      transition: all 0.2s;
    }
  }
  .text {
    transition: all 0.2s;
    color: #17172e;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
    width: max-content;
  }
  &.active {
    .text {
      color: #37cc8f;
    }
    .icon {
      svg {
        path {
          fill: #37cc8f;
        }
      }
    }
  }
}

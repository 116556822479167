.wrapper {
  position: fixed;
  width: 100%;
  max-width: 650px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-auto-columns: auto;
  text-align: center;
  background: #fff;
}
.img {
  width: 100%;
  max-width: -webkit-fill-available;
  margin: 20px;
  margin-top: 0;
  img {
    width: 100%;
  }
}
.btnWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
}
.timer {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 17.6px */
  margin-left: 20px;
}
.btns {
  display: flex;
  align-items: center;
}
.download,
.preview {
  margin: 0px 10px;
  cursor: pointer;
  color: #17172e;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 17.6px */
}
.preview {
  margin-right: 20px;
}
.download {
  transition: all 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background: #37cc8f;
  padding: 0px 14px;
  height: 38px;

  &:hover {
    background: #32c487;
  }
}
.preview {
  transition: all 0.2s;
  cursor: pointer;
  border-radius: 10px;
  background: #f6f6f6;
  padding: 10px 14px;
  display: flex;
  align-items: center;

  &:hover {
    background: #dfdddd;
  }
}
.head {
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 20px;
  span {
    color: #17172e;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 15.4px */
  }
}

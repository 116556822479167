.wrapper {
  margin-top: 8px;
}

.tab {
  padding: 16px 12px;
  display: flex;
  align-items: center;
  transition: all 0.2s;
  border-radius: 10px;
  border: 1px solid transparent;
  cursor: pointer;

  &:hover {
    border: 1px solid #37cc8f;
  }
  &.disable {
    pointer-events: none;
    opacity: 0.4;

    &:hover {
      border: 1px solid transparent;
    }
  }
}
.icon {
  margin-right: 12px;
}
.title {
  color: #17172e;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
  background: #fff;
}

.back {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #dfdfdf;
  height: 36px;
  margin-bottom: 8px;
  cursor: pointer;
  svg {
    margin-right: 4px;
  }
  .logo {
    position: absolute;
    right: 8px;
    top: 12px;
    margin: 0;
  }
  span {
    position: relative;
    bottom: -1px;
    color: #17172e;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.items {
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
  width: calc(100% - 24px);
}

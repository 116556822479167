.wrapper {
  height: 60px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.9);
}

.item {
  display: flex;
  flex-direction: column;
  transition: all 0.2s;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin: 5px 20px;
  span {
    transition: all 0.2s;
    padding-top: 4px;
    color: #17172e;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  svg {
    path {
      transition: all 0.2s;
    }
  }

  &:hover {
    svg {
      path {
        fill: #37cc8f;
      }
    }
    span {
      color: #37cc8f;
    }
  }
  &.active {
    svg {
      path {
        fill: #37cc8f;
      }
    }
    span {
      color: #37cc8f;
    }
  }
}
.line {
  background: #17172e;
  height: 16px;
  width: 1px;
}

.delete {
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60px;
  border-radius: 0px 10px 10px 0px;
  background: rgba(228, 31, 42, 0.8);
  padding: 0px 14px;
  span {
    padding-top: 4px;
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &:hover {
    background: rgba(228, 31, 42, 1);
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 0px;
  width: calc(32% - 8px);
  margin: 4px;
  transition: all 0.2s;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid #37cc8f;
  }

  &.active {
    border: 1px solid #37cc8f;

  }
}
.img {
    padding: 0px 20px;
    height: 59px;

    img {
        width: 100%;
        height: 100%;
    }
}
.text {
  margin-top: 8px;
  color: #17172e;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  padding: 0px 8px;
}

.wrapper {
  position: absolute;
  top: 20px;
  left: -180px;
  padding: 8px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;

  &.offline {
    .value {
      background: rgba(228, 31, 42, 0.9);
      color: #fff;
    }
  }
  &.pending {
    .value {
      background: rgba(240, 243, 27, 0.9);
    }
  }
}

.label {
  color: #343a40;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 15.6px */
}

.value {
  color: #343a40;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 15.6px */
  margin-left: 12px;
  padding: 4px 8px;
  border-radius: 50px;
  background: #4ece9a;


  &::first-letter {
    text-transform: uppercase;
  }
}

.timer {
  color: #fff;
  text-align: center;
  font-size: 80px;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 80px */
  display: flex;
  align-items: center;
  justify-content: center;

  .m {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 110px;
  }
  .s {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 0px 4px;
  }
  .ms {
    display: flex;
    width: 105px;
  }
}

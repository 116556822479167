.checkbox {
  position: relative;
  width: 44px;
  height: 22px;
  border-radius: 12px;
  background: #ced4da;
  cursor: pointer;

  .point {
    transition: all 0.2s;
    position: absolute;
    left: 2px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    width: 18px;
    height: 18px;
    background: #fff;
  }

  &.active {
    .point {
      left: calc(100% - 20px);
      background: #37cc8f;
    }
  }
}

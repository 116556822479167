.wrapper {
  position: fixed;
  width: 100%;
  max-width: 650px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-auto-columns: auto;
  text-align: center;
  background: #fff;
}

.head {
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 20px;
  span {
    color: #17172e;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 15.4px */
  }
}

.close {
  cursor: pointer;
}

.title {
  color: #343a40;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 31.2px */
}
.subtitle {
  margin: 0 auto;
  margin-top: 12px;
  margin-bottom: 20px;
  color: #343a40;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  max-width: 520px;
  line-height: 130%; /* 20.8px */
}
.reload {
  padding: 20px 10px;
  border-radius: 500px;
  background: #37cc8f;
  cursor: pointer;
  transition: all 0.2s;
  width: 250px;
  color: #343a40;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.16px;
  margin: 0 auto;
  margin-bottom: 24px;
  &:hover {
    background: #30b87f;
  }
}

.wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 100%;
}
.add {
    position: absolute;
    top: 20px;
    left: 20px;
    background: #ccc;
    border-radius: 6px;
    padding: 12px 18px;
    cursor: pointer;
    font-size: 24px;
}
.wrapper {
  position: relative;
}
.title {
  transition: all 0.2s;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #ced4da;
  padding: 7px;
  display: flex;
  align-items: center;

  &.active {
    border: 1px solid #37cc8f;
  }
}

.content {
  position: absolute;
  top: 40px;
  padding: 4px 12px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
}

.item {
  transition: all .2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 4px 0;

  .accept {
    display: flex;
    align-items: center;
    transition: all 0.2s;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    margin-right: 4px;
    svg {
        width: 100%;
        height: 100%;
      transition: all 0.2s;
      opacity: 0;
    }
  }
  .text {
    color: #17172e;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
    width: max-content;

  }

  &:hover {
    .accept {
      svg {
        opacity: 0.4;
      }
    }
  }

  &.active {
    .accept {
      border: 1px solid #37CC8F;
      background: #37CC8F;
      svg {
        opacity: 1;
      }
    }
  }
}

.wrapper {
  position: fixed;
  width: 100%;
  max-width: 580px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  height: auto;
  border-radius: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 40px 0px rgba(22, 21, 22, 0.2);
  footer {
    margin-top: 30px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: center;
    border: unset;
    box-shadow: unset;
  }
}
.text {
  padding: 0px 20px;
  color: #161516;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 8px;
}
.error {
  margin-top: 4px;
  color: #e41f2a;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
}
.head {
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 20px;
  justify-content: flex-end;

  span {
    color: #17172e;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 15.4px */
  }
}
.mail {
  padding: 0px 60px;
  display: flex;
  flex-direction: column;
  max-width: 460px;
  margin: 0 auto;
  label {
    color: #17172e;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    margin-bottom: 12px;
  }
  input {
    padding: 22px 20px;
    border-radius: 10px;
    border: 1px solid #ced4da;
    background: #fff;
    outline: none;
  }
}
.copy {
  color: #161516;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  margin-right: 12px;
  word-wrap: break-word;
  &Wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &Btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    svg {
      margin-right: 6px;
      path {
        stroke: #37cc8f;
      }
    }
    span {
      color: #37cc8f;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.16px;
    }
  }
}

.title {
  &Wrapper {
    text-align: center;
    margin-bottom: 30px;
  }
  color: #161516;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 39px */
  margin-bottom: 4px;
}

.subtitle {
  color: rgba(22, 21, 22, 0.8);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
}

.nav {
  display: flex;
  justify-content: end;
  padding: 12px 20px;
  border-top: 1px solid #dfdfdf;
  background: #fff;
  margin-top: 20px;
}

.cancel {
  cursor: pointer;
  transition: all 0.2s;
  padding: 12px 20px;
  color: #17172e;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 17.6px */
  border-radius: 10px;
}

.accept {
  cursor: pointer;
  transition: all 0.2s;
  color: #17172e;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 17.6px */
  padding: 12px 20px;
  border-radius: 10px;
  background: #37cc8f;

  &:hover {
    background: #35c287;
  }

  &.active {
    pointer-events: inherit;
    opacity: 1;
  }
}

.checkbox {
  width: 22px;
  height: 22px;
  border: 1px solid #dfdfdf;
  margin-right: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  &::after {
    transition: all 0.2s;
    opacity: 0;
    border-radius: 4px;
    content: "";
    width: 18px;
    height: 18px;
    background: #37cc8f;
  }
}

.checker {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  margin: 0 auto;

  span {
    color: #17172e;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
  }

  &.active {
    .checkbox {
      &::after {
        opacity: 1;
      }
    }
  }
}
